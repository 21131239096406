@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.communication-modal {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include mq('desktop-s', min) {
    width: 590px;
  }

  &__title {
    font-size: rem(18);
    color: $text-color-super-darken;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 0 0;
    text-align: center;
  }

  &__text {
    font-size: rem(16);
    font-weight: 300;
    color: $text-color;
    text-align: center;
    line-height: 19px;
    margin: 20px 0;
  }

  &__images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
  }

  &__brand {
    width: 40px;
    margin: 0 0 20px;
  }
}
